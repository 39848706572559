var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('md-app', {
    attrs: {
      "md-waterfall": "",
      "md-mode": "fixed"
    }
  }, [_c('md-app-toolbar', {
    attrs: {
      "id": "header",
      "toggler": _vm.toggleMenuVisibility
    }
  }), _c('md-app-content', {
    staticClass: "u--padding-zero u--layout-flex u--layout-flex-column"
  }, [_c('HeroHeader'), _c('router-view'), _c('page-footer', {
    attrs: {
      "id": "footer"
    }
  })], 1), _c('md-app-drawer', {
    attrs: {
      "md-active": _vm.menuVisible
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.menuVisible = $event;
      },
      "update:md-active": function ($event) {
        _vm.menuVisible = $event;
      }
    }
  }, [_c('mdDrawer', {
    attrs: {
      "id": "leftdrawer"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }