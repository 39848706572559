var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('md-app', {
    attrs: {
      "md-waterfall": "",
      "md-mode": "fixed",
      "id": "metamine_app"
    }
  }, [_c('md-app-toolbar', {
    staticClass: "md-large md-dense md-primary adjust_metamine-toolbar"
  }, [_c('div', {
    staticClass: "md-toolbar-row"
  }, [_c('div', {
    staticClass: "contactus_radios md-card-actions"
  }, [_c('md-button', {
    staticClass: "md-icon-button",
    on: {
      "click": _vm.toggleMenu
    }
  }, [_c('md-icon', {
    staticClass: "metamine_menu-icon"
  }, [_vm._v("menu")])], 1), _c('router-link', {
    attrs: {
      "to": "/mm"
    }
  }, [_c('span', {
    staticClass: "md-title adjust_metamine-title"
  }, [_vm._v("MetaMine")])])], 1), _c('div', {
    staticClass: "md-toolbar-section-end"
  }, [_c('div', [_c('nav', {
    staticClass: "nav_menu nav_menu--lightbg"
  }, [_c('ul', {
    staticClass: "nav_ul",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('li', [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("ABOUT")]), _c('div', {
    staticClass: "nav_menu--siblings-mm"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/mm/teams"
    }
  }, [_c('a', [_vm._v("About Us")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/nm/how"
    }
  }, [_c('a', [_vm._v("How To")])])], 1)])]), _c('li', [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("VISUALIZE")]), _c('div', {
    staticClass: "nav_menu--siblings-mm"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/explorer"
    }
  }, [_c('a', [_vm._v("Browse Data")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/mm/metamaterial_visualization_nu"
    }
  }, [_c('a', [_vm._v("Material Visualization")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/explorer/dataset"
    }
  }, [_c('a', [_vm._v("Explore Curated Datasets")])])], 1)])]), _c('li', [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("TOOLS")]), _c('div', {
    staticClass: "nav_menu--siblings-mm"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-mm-lists",
    attrs: {
      "to": "/mm/pixelunit"
    }
  }, [_c('a', [_vm._v("Geometry Explorer")])])], 1)])]), _c('li', [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "/nm/contact"
    }
  }, [_vm._v("CONTACT US")])])]), _c('li', [_c('div', {
    staticClass: "nav_menu--container"
  }, [!_vm.isAuth ? _c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "/secure"
    }
  }, [_c('md-icon', {
    staticClass: "metamine_menu-icon"
  }, [_vm._v("person")]), _vm._v(" LOGIN/REGISTER ")], 1) : _c('a', {
    staticClass: "u--default-size nav_menu--handler",
    on: {
      "click": function ($event) {
        return _vm.$store.dispatch('logout');
      }
    }
  }, [_vm._v(" Hi " + _vm._s(_vm.displayName) + " ")])])])])])])])])]), _c('md-app-drawer', {
    attrs: {
      "md-active": _vm.toggleMenuVisibility
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.toggleMenuVisibility = $event;
      },
      "update:md-active": function ($event) {
        _vm.toggleMenuVisibility = $event;
      }
    }
  }, [_c('drawers')], 1), _c('md-app-content', {
    staticClass: "u_height--max"
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }