var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "md-app-side-drawer u_display-flex u--layout-flex-column"
  }, [_vm.sideDrawerVisiblity ? _c('div', {
    staticClass: "md-app-internal-drawer u--layout-flex u--layout-flex-column u--layout-flex-justify-sb metamine_footer-ref-header",
    staticStyle: {
      "min-height": "80vh"
    }
  }, [_c('md-list', {
    staticClass: "utility-transparentbg",
    attrs: {
      "md-expand-single": "true"
    }
  }, [_vm.sideDrawerVisiblity ? _c('button', {
    staticClass: "utility-gridborder return-btn return-btn-text u--margin-leftsm",
    staticStyle: {
      "place-self": "end"
    },
    on: {
      "click": _vm.toggleSideDrawerVisibility
    }
  }, [_c('md-icon', {
    staticClass: "return-btn-text metamine_intro-header u_margin-none"
  }, [_vm._v("keyboard_arrow_left")]), _c('md-tooltip', {
    attrs: {
      "md-direction": "right"
    }
  }, [_vm._v("Close Menu")])], 1) : _vm._e(), _c('router-link', {
    attrs: {
      "to": '/portal/user',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate,
          href = _ref.href;
        return [_c('md-list-item', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_c('i', {
          staticClass: "md-icon md-icon-font u--default-size md-theme-default"
        }, [_vm._v("manage_accounts")]), _c('span', {
          staticClass: "md-list-item-text utility-navfont"
        }, [_vm._v("Account")])])];
      }
    }], null, false, 4225626775)
  }), _c('md-divider'), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("bar_chart")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Chart")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": "/portal/user/favorite-charts",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate,
          href = _ref2.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Favorite Chart")])];
      }
    }], null, false, 1431337840)
  })], 1)], 1), _c('md-divider'), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("account_tree")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("My Curations")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": '/portal/user/approved-curations',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var navigate = _ref3.navigate,
          href = _ref3.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_c('md-icon', {
          staticClass: "utility-navfonticon"
        }, [_vm._v("thumb_up_alt")]), _vm._v("Approved Curations")], 1)];
      }
    }], null, false, 1903906872)
  }), _c('router-link', {
    attrs: {
      "to": "/portal/user/unapproved-curations",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var navigate = _ref4.navigate,
          href = _ref4.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_c('md-icon', {
          staticClass: "utility-navfonticon"
        }, [_vm._v("hive")]), _vm._v("Unapproved Curations ")], 1)];
      }
    }], null, false, 620519339)
  })], 1)], 1), _c('md-divider')], 1)], 1) : _vm._e(), !_vm.sideDrawerVisiblity ? _c('button', {
    staticClass: "utility-gridborder return-btn metamine_footer-ref-header",
    on: {
      "click": _vm.toggleSideDrawerVisibility
    }
  }, [_c('md-icon', {
    staticClass: "return-btn-text metamine_intro-header u_margin-none"
  }, [_vm._v("keyboard_arrow_right")]), _c('md-tooltip', {
    attrs: {
      "md-direction": "right"
    }
  }, [_vm._v("Open Menu")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }