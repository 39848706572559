var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('md-snackbar', {
    staticClass: "md-snackbar-adjust",
    attrs: {
      "md-position": _vm.position,
      "md-active": _vm.show,
      "md-duration": !_vm.snackbar.duration ? Infinity : _vm.snackbar.duration
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.show = $event;
      },
      "update:md-active": function ($event) {
        _vm.show = $event;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.message) + " "), _c('span', [_vm.snackbar.action && !_vm.snackbar.duration ? _c('md-button', {
    staticClass: "md-primary",
    attrs: {
      "id": "snackbarAction"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.snackBarAction.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.snackbar.callToActionText))]) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }