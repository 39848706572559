var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "utility-roverflow"
  }, [_c('md-app-drawer', {
    staticClass: "u_toggle-display-off",
    attrs: {
      "md-active": _vm.sideBar,
      "md-permanent": "clipped"
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.sideBar = $event;
      },
      "update:md-active": function ($event) {
        _vm.sideBar = $event;
      }
    }
  }, [_c('md-list', {
    staticClass: "md-dense"
  }, [_c('li', {
    staticClass: "md-list-item"
  }, [_c('router-link', {
    staticClass: "md-list-item-link md-list-item-container md-button-clean",
    attrs: {
      "mdripple": "true",
      "to": "/portal"
    }
  }, [_c('div', {
    staticClass: "md-list-item-content md-list-item-content-reduce u--layout-flex-justify-fs md-ripple"
  }, [_c('i', {
    staticClass: "md-icon md-icon-font u--default-size md-theme-default"
  }, [_vm._v("manage_accounts")]), _c('span', {
    staticClass: "md-body-1 u--color-black u--margin-neg-left"
  }, [_vm._v("Account")])])])], 1), _vm._l(_vm.links, function (link, index) {
    return _c('div', {
      key: index + 'D',
      staticClass: "u_width--max"
    }, [_c('div', {
      staticClass: "u--font-emph-l u_margin-top-small u--padding-rl-xs-mobile",
      attrs: {
        "md-elevation": "0"
      }
    }, [_c('span', {
      staticClass: "md-body-2 u--color-grey-sec"
    }, [_vm._v(_vm._s(link.name))])]), _c('md-divider'), _vm._l(link.children, function (child, i) {
      return _c('li', {
        key: i,
        staticClass: "md-list-item"
      }, [_c('router-link', {
        staticClass: "md-list-item-link md-list-item-container md-button-clean",
        attrs: {
          "mdripple": "true",
          "to": child.link
        }
      }, [_c('div', {
        staticClass: "md-list-item-content md-list-item-content-reduce u--layout-flex-justify-fs md-ripple"
      }, [_c('i', {
        staticClass: "md-icon md-icon-font u--default-size md-theme-default"
      }, [_vm._v(_vm._s(child.icon))]), _c('span', {
        staticClass: "md-body-1 u--color-black u--margin-neg-left"
      }, [_vm._v(_vm._s(child.name))])])])], 1);
    }), _vm._l(link.hrefChildren, function (child, i) {
      return _c('li', {
        key: i + 'h',
        staticClass: "md-list-item"
      }, [_c('a', {
        staticClass: "md-list-item-link md-list-item-container md-button-clean",
        attrs: {
          "mdripple": "true",
          "href": child.href
        }
      }, [_c('div', {
        staticClass: "md-list-item-content md-list-item-content-reduce u--layout-flex-justify-fs md-ripple"
      }, [_c('i', {
        staticClass: "md-icon md-icon-font u--default-size md-theme-default"
      }, [_vm._v(_vm._s(child.icon))]), _c('span', {
        staticClass: "md-body-1 u--color-black u--margin-neg-left"
      }, [_vm._v(_vm._s(child.name))])])])]);
    })], 2);
  })], 2)], 1), _c('div', {
    staticClass: "footer_content-mobile u_myprofile--image"
  }, [_c('nav', {
    staticClass: "nav_menu"
  }, [_c('ul', {
    staticClass: "u_centralize_text"
  }, _vm._l(_vm.links, function (link, index) {
    return _c('li', {
      key: index + 'e',
      staticClass: "u_margin-right-small"
    }, [_c('div', {
      staticClass: "nav_menu--container"
    }, [_c('a', {
      staticClass: "u--default-size nav_menu--handler u--color-primary"
    }, [_c('span', {
      staticClass: "u--color-primary"
    }, [_vm._v(_vm._s(link.name) + " ")])]), !!link.children && !!link.children.length ? _c('div', {
      staticClass: "nav_menu--siblings"
    }, _vm._l(link.children, function (child, i) {
      return _c('router-link', {
        key: i + 'i',
        staticClass: "nav_menu--siblings-lists",
        attrs: {
          "to": child.link
        }
      }, [_c('a', [_vm._v(_vm._s(child.name))])]);
    }), 1) : _vm._e()])]);
  }), 0)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }