var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section_footer u_margin-top-auto"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "grid grid_col-space-right grid_gap-smaller"
  }, [_c('div', {
    staticClass: "footer_content u_toggle-display-off"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "footer_icon-img",
    attrs: {
      "src": require("@/assets/img/materialsmine_logo.png"),
      "href": "/",
      "alt": "Materialsmine Logo"
    }
  })]), _c('span', {
    staticClass: "footer_icon-text"
  }, [_vm._v("© 2014 - " + _vm._s(new Date().getFullYear()) + " Nanomine. All Rights Reserved.")])], 1), _c('div', {
    staticClass: "footer_content"
  }, [_c('div', {
    staticClass: "grid grid_col-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "footer_list"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/nm/about"
    }
  }, [_vm._v("About Us")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/nm/how"
    }
  }, [_vm._v("How To")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/nm/news"
    }
  }, [_vm._v("Research & News")])], 1)])]), _c('div', {
    staticClass: "footer_list"
  }, [_c('ul', [_vm._m(1), _vm._m(2), _c('li', [_c('router-link', {
    attrs: {
      "to": "/nm/contact"
    }
  }, [_vm._v("Contact Us")])], 1)])])])])]), _c('div', {
    staticClass: "footer_content-mobile"
  }, [_c('img', {
    staticClass: "footer_icon-img",
    attrs: {
      "src": require("@/assets/img/materialsmine_logo.png"),
      "alt": "Materialsmine Logo"
    }
  }), _c('span', {
    staticClass: "footer_icon-text"
  }, [_vm._v("© 2014-" + _vm._s(new Date().getFullYear()) + " Materialsmine. All Rights Reserved.")])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer_list"
  }, [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "/nm"
    }
  }, [_vm._v("Nanomine")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/mm"
    }
  }, [_vm._v("Metamine")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://oarc.duke.edu/privacy/duke-university-privacy-statement",
      "target": "_blank"
    }
  }, [_vm._v("Privacy Policy")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://security.duke.edu/node/82",
      "target": "_blank"
    }
  }, [_vm._v("Terms of Use")])]);

}]

export { render, staticRenderFns }