var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('md-app', [_c('md-app-toolbar', {
    attrs: {
      "id": "header",
      "toggler": _vm.toggleMenuVisibility
    }
  }), _vm.isAdmin ? _c('md-app-content', {
    staticClass: "viz-u-postion__rel"
  }, [_c('div', {
    staticClass: "md-layout u_width--max md-gutter md-alignment-top-center"
  }, [_c('div', {
    staticClass: "md-layout-item md-size-60 md-medium-size-90 md-xsmall-size-95"
  }, [_c('profile-header'), _c('div', {
    staticClass: "u_margin-top-small u_display-flex section_md-header md-layout-row md-theme-demo-light md-scrollbar"
  }, [_c('md-side-nav'), _c('div', {
    staticClass: "u_width--max md-content u--margin-pos utility-roverflow"
  }, [!!_vm.info.name ? _c('div', [_c('h2', {
    staticClass: "md-title u--color-black",
    staticStyle: {
      "margin-bottom": "0.4rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.info.name) + " ")])]) : _vm._e(), _c('router-view')], 1)], 1)], 1)])]) : _c('md-app-content', {
    staticClass: "u_margin-top-small u_display-flex section_md-header md-layout-row md-theme-demo-light md-scrollbar"
  }, [_c('div', {
    staticClass: "md-size-20 u--margin-leftsm"
  }, [_c('user-side-bar')], 1), _c('div', {
    staticClass: "md-content u--margin-pos utility-roverflow u_width--max"
  }, [_c('div', {
    staticClass: "u_width--max"
  }, [_c('profile-header'), _c('div', {
    staticClass: "u_margin-top-small u_width--max"
  }, [_c('div', {
    staticClass: "md-content u_width--max"
  }, [!!_vm.info.name ? _c('div', [_c('h2', {
    staticClass: "md-title u--color-black",
    staticStyle: {
      "margin-bottom": "0.4rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.info.name) + " ")])]) : _vm._e(), _c('router-view')], 1)])], 1)])]), _c('md-app-drawer', {
    attrs: {
      "md-active": _vm.menuVisible
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.menuVisible = $event;
      },
      "update:md-active": function ($event) {
        _vm.menuVisible = $event;
      }
    }
  }, [_c('mdDrawer', {
    attrs: {
      "id": "leftdrawer"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }