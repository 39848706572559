var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('md-app-toolbar', {
    staticClass: "md-dense md-primary",
    attrs: {
      "id": "reset_bg"
    }
  }, [_c('div', {
    staticClass: "md-toolbar-row viz-u-postion__rel"
  }, [_c('div', {
    staticClass: "md-toolbar-section-start"
  }, [_c('md-button', {
    staticClass: "md-icon-button",
    on: {
      "click": _vm.toggler
    }
  }, [_c('md-icon', [_vm._v("menu")])], 1), _c('router-link', {
    staticClass: "header-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "md-title"
  }, [_c('img', {
    attrs: {
      "id": "logo",
      "src": require("@/assets/img/materialsmine_logo_sm.png")
    }
  })])])], 1), _c('div', {
    staticClass: "md-toolbar-section-end header_nav"
  }, [_c('div', {
    staticClass: "nav nav_menu u--inline"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/nm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate,
          href = _ref.href;
        return [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("NanoMine")])];
      }
    }])
  })], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/mm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate,
          href = _ref2.href;
        return [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Metamine")])];
      }
    }])
  })], 1)])]), _c('div', {
    staticClass: "u--inline"
  }, [_vm.isAuth ? _c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler u_color_white",
    staticStyle: {
      "font-size": "1.2rem !important"
    }
  }, [_vm._v(" Hi " + _vm._s(_vm.displayName) + " ")]), _c('div', {
    staticClass: "nav_menu--siblings nav_menu--sibheader"
  }, [_c('span', {
    staticClass: "nav_menu--siblings-lists",
    on: {
      "click": function ($event) {
        return _vm.$store.dispatch('auth/logout');
      }
    }
  }, [_c('a', {
    attrs: {
      "id": "authmenu"
    }
  }, [_vm._v("Logout")])])])]) : _c('div', [_c('a', {
    staticClass: "btn btn--tertiary btn--noradius",
    attrs: {
      "href": "/secure",
      "id": "authmenu"
    }
  }, [_vm._v("Login/Register")])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }