var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('md-app', {
    attrs: {
      "md-waterfall": "",
      "md-mode": "fixed"
    }
  }, [_c('md-app-toolbar', {
    attrs: {
      "showTop": _vm.showTop,
      "toggler": _vm.toggleMenu
    }
  }), _c('md-app-drawer', {
    attrs: {
      "md-active": _vm.toggleMenuVisibility
    },
    on: {
      "update:mdActive": function ($event) {
        _vm.toggleMenuVisibility = $event;
      },
      "update:md-active": function ($event) {
        _vm.toggleMenuVisibility = $event;
      }
    }
  }, [_c('drawers')], 1), _c('md-app-content', {
    staticClass: "u--padding-zero u_height--max"
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }