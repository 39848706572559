var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "md-app-internal-drawer u--layout-flex u--layout-flex-column u--layout-flex-justify-sb"
  }, [_c('md-list', {
    staticClass: "utility-transparentbg",
    attrs: {
      "md-expand-single": "true"
    }
  }, [_c('md-toolbar', {
    staticClass: "md-transparent u--font-emph-l u_toggle-display-off",
    attrs: {
      "md-elevation": "0"
    }
  }, [_c('small', [_vm._v("App Navigation")])]), _c('div', {
    staticClass: "visualize-pagination-width-mod footer_content-mobile u_margin-none"
  }, [_c('md-toolbar', {
    staticClass: "md-transparent u--font-emph-l",
    attrs: {
      "md-elevation": "0"
    }
  }, [_c('small', [_vm._v("Welcome " + _vm._s(_vm.name))])])], 1), _c('md-divider'), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("home")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Home")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": "/explorer",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate,
          href = _ref.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" Explorer ")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/mm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate,
          href = _ref2.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" Metamine ")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/nm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var navigate = _ref3.navigate,
          href = _ref3.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Nanomine")])];
      }
    }])
  })], 1)], 1), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("groups")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("About")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": '/nm/about',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var navigate = _ref4.navigate,
          href = _ref4.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" About Us ")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": '/nm/how',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var navigate = _ref5.navigate,
          href = _ref5.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("How To")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": '/nm/news',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var navigate = _ref6.navigate,
          href = _ref6.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Research + News")])];
      }
    }])
  })], 1)], 1), _c('md-toolbar', {
    staticClass: "md-transparent u--font-emph-l u_margin-top-small",
    attrs: {
      "md-elevation": "0"
    }
  }, [_c('small', [_vm._v("Explore")])]), _c('md-divider'), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("view_comfy")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Visualize")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": "/explorer",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var navigate = _ref7.navigate,
          href = _ref7.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Browse Data")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/explorer/chart",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var navigate = _ref8.navigate,
          href = _ref8.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Chart Gallery")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/explorer/images",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var navigate = _ref9.navigate,
          href = _ref9.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Image Gallery")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/explorer/dataset",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var navigate = _ref10.navigate,
          href = _ref10.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Dataset Gallery")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/explorer/xmls",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var navigate = _ref11.navigate,
          href = _ref11.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("View Xmls")])];
      }
    }])
  })], 1)], 1), _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("handyman")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Tools")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": '/explorer/tools',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref12) {
        var navigate = _ref12.navigate,
          href = _ref12.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Module & Simulation Tools")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": '/nm/tools/plot-curation',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var navigate = _ref13.navigate,
          href = _ref13.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Easy CSV Plotter")])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": '/explorer/sparql',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref14) {
        var navigate = _ref14.navigate,
          href = _ref14.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Sparql Query Interface")])];
      }
    }])
  })], 1)], 1), _vm.isAuth ? _c('md-toolbar', {
    staticClass: "md-transparent u--font-emph-l u_margin-top-small",
    attrs: {
      "md-elevation": "0"
    }
  }, [_c('small', [_vm._v("User Dashboard")])]) : _vm._e(), _vm.isAuth ? _c('md-divider') : _vm._e(), _vm.isAuth ? _c('md-list-item', {
    attrs: {
      "md-expand": ""
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("upload")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Curate")]), _c('md-list', {
    attrs: {
      "slot": "md-expand"
    },
    slot: "md-expand"
  }, [_c('router-link', {
    attrs: {
      "to": '/explorer/curate/spreadsheet',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref15) {
        var navigate = _ref15.navigate,
          href = _ref15.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Upload a Spreadsheet")])];
      }
    }], null, false, 1551933812)
  }), _c('router-link', {
    attrs: {
      "to": '/explorer/curate/stepper',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref16) {
        var navigate = _ref16.navigate,
          href = _ref16.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Fill a Form")])];
      }
    }], null, false, 4024003632)
  }), _c('router-link', {
    attrs: {
      "to": '/explorer/curate/sdd',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var navigate = _ref17.navigate,
          href = _ref17.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Submit SDD")])];
      }
    }], null, false, 1075823343)
  }), _c('router-link', {
    attrs: {
      "to": '/explorer/chart/editor/new',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var navigate = _ref18.navigate,
          href = _ref18.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Create New Chart")])];
      }
    }], null, false, 1627946556)
  }), _c('router-link', {
    attrs: {
      "to": '/explorer/curate/validlist',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var navigate = _ref19.navigate,
          href = _ref19.href;
        return [_c('md-list-item', {
          staticClass: "md-inset",
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Add Xlsx List Entry")])];
      }
    }], null, false, 2910210304)
  })], 1)], 1) : _vm._e(), _vm.isAuth && _vm.isAdmin ? _c('md-toolbar', {
    staticClass: "md-transparent u--font-emph-l u_margin-top-small",
    attrs: {
      "md-elevation": "0"
    }
  }, [_c('small', [_vm._v("Admin")])]) : _vm._e(), _vm.isAuth && _vm.isAdmin ? _c('md-divider') : _vm._e(), _vm.isAuth && _vm.isAdmin ? _c('router-link', {
    attrs: {
      "to": '/portal',
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref20) {
        var navigate = _ref20.navigate,
          href = _ref20.href;
        return [_c('md-list-item', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_c('md-icon', {
          staticClass: "utility-navfonticon"
        }, [_vm._v("admin_panel_settings")]), _c('span', {
          staticClass: "md-list-item-text utility-navfont"
        }, [_vm._v("Admin Center")])], 1)];
      }
    }], null, false, 606706190)
  }) : _vm._e()], 1), _c('md-list', {
    staticClass: "utility-transparentbg"
  }, [_vm.isAuth ? _c('md-list-item', {
    on: {
      "click": function ($event) {
        return _vm.$store.dispatch('auth/logout');
      }
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("logout")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Log out")])], 1) : _vm._e(), !_vm.isAuth ? _c('md-list-item', {
    on: {
      "click": _vm.login
    }
  }, [_c('md-icon', {
    staticClass: "utility-navfonticon"
  }, [_vm._v("login")]), _c('span', {
    staticClass: "md-list-item-text utility-navfont"
  }, [_vm._v("Login")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }