var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container",
    attrs: {
      "id": "app"
    }
  }, [_c('router-view'), _c('Snackbar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }