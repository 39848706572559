var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('router-link', {
    staticClass: "header-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('div', {}, [_c('img', {
    attrs: {
      "src": require("@/assets/img/materialsmine_logo.png"),
      "alt": "MaterialsMine Logo"
    }
  })])]), _vm._m(0), _c('div', {
    staticClass: "header_nav header_404_nav"
  }, [_vm._m(1), _c('div', {
    staticClass: "u--inline"
  }, [_vm.$store.getters['auth/isAuthenticated'] ? _c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    staticStyle: {
      "color": "#fff",
      "font-size": "1.2rem !important"
    }
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_vm._v("perm_identity")]), _vm._v(" " + _vm._s(_vm.$store.getters['auth/displayName']) + " ")]), _c('div', {
    staticClass: "nav_menu--siblings nav_menu--sibheader"
  }, [_c('span', {
    staticClass: "nav_menu--siblings-lists",
    on: {
      "click": function ($event) {
        return _vm.$store.dispatch('logout');
      }
    }
  }, [_c('a', [_vm._v("Logout")])])])]) : _c('div', [_c('a', {
    staticClass: "btn btn--tertiary btn--noradius",
    attrs: {
      "href": "/secure"
    }
  }, [_vm._v("Login/Register")])])])])], 1)]), _c('div', {
    staticClass: "page-not-found"
  }, [_c('h1', {
    staticClass: "page-not-found-header u_margin-bottom-small"
  }, [_vm._v("Oops!")]), _c('h2', {
    staticClass: "u_margin-top-med u_margin-bottom-small"
  }, [_vm._v(" 404 - Page Not Found ")]), _c('p', [_vm._v(" The page you are looking for might have been removed, had its name changed or is temporarily unavailable. Would you like to go back to "), _c('router-link', {
    attrs: {
      "to": ""
    }
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("previous page")])]), _vm._v("? ")], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nav_mobile-icon"
  }, [_c('div', {
    staticClass: "nav_mobile-icon-menu"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nav nav_menu u--inline"
  }, [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "/nm"
    }
  }, [_vm._v("NanoMine")])]), _c('li', [_c('a', {
    attrs: {
      "href": "/mm"
    }
  }, [_vm._v("MetaMine")])])])]);

}]

export { render, staticRenderFns }