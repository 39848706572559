var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "viz-u-postion__rel"
  }, [_c('md-app-toolbar', {
    staticClass: "md-large md-dense md-primary",
    style: [_vm.transition, !_vm.showTop && _vm.hideHeaderView],
    attrs: {
      "id": "reset_bg"
    }
  }, [_c('div', {
    staticClass: "md-toolbar-row"
  }, [_c('div', {
    staticClass: "md-toolbar-section-start"
  }, [_c('md-button', {
    staticClass: "md-icon-button",
    on: {
      "click": _vm.toggler
    }
  }, [_c('md-icon', [_vm._v("menu")])], 1), _c('router-link', {
    staticClass: "header-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "md-title"
  }, [_c('img', {
    attrs: {
      "id": "logo",
      "src": require("@/assets/img/materialsmine_logo_sm.png")
    }
  })])])], 1), _c('div', {
    staticClass: "md-toolbar-section-end md-toolbar-section-end_adjust"
  }, [_c('div', {
    staticClass: "nav nav_menu u--inline"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/nm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var navigate = _ref.navigate,
          href = _ref.href;
        return [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("NanoMine")])];
      }
    }])
  })], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/mm",
      "custom": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var navigate = _ref2.navigate,
          href = _ref2.href;
        return [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v("Metamine")])];
      }
    }])
  })], 1), _c('li', [_vm.isAuth ? _c('span', {
    staticClass: "u_color_white u--font-emph-m"
  }, [_vm._v(" Hi " + _vm._s(_vm.displayName))]) : _vm._e(), !_vm.isAuth ? _c('a', {
    staticClass: "md-icon-button large u_color_white u--font-emph-m u_margin-top-small",
    attrs: {
      "href": "/secure"
    }
  }, [_vm._v(" Login ")]) : _vm._e()])])])])]), _c('div', {
    staticClass: "md-toolbar-row u_margin-top-med u_toggle-display-off"
  }, [_c('md-tabs', {
    staticClass: "md-primary",
    attrs: {
      "id": "reset_tab_bg",
      "md-sync-route": ""
    }
  }, [_c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer",
      "id": "tab-home",
      "md-label": "Search",
      "exact": ""
    }
  }), _c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer/visualization",
      "id": "tab-visualization",
      "md-label": "Visualization"
    }
  }), _c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer/curate",
      "id": "tab-curate",
      "md-label": "Curate"
    }
  }), _c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer/tools",
      "id": "tab-tools",
      "md-label": "Tools"
    }
  }), _c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer/parameterized_query",
      "id": "tab-query",
      "md-label": "Parameterized Query",
      "exact": ""
    }
  }), _c('md-tab', {
    staticClass: "_menutabs",
    attrs: {
      "to": "/explorer/sparql",
      "id": "tab-sparql",
      "md-label": "SPARQL Query",
      "exact": ""
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }