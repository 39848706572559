var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "u_margin-top-small"
  }, [_c('div', {
    staticClass: "u_display-flex u--layout-flex-justify-fs",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c('router-link', {
    staticClass: "viz-sample__loading",
    attrs: {
      "to": {
        name: 'PortalHome'
      }
    }
  }, [_c('md-avatar', {
    staticClass: "md-avatar-icon md-large md-primary u_margin-none u--bg utility-gridborder"
  }, [_c('md-ripple', {
    staticClass: "md-title"
  }, [_vm._v(" " + _vm._s(_vm.getInitials) + " ")])], 1)], 1), _c('div', {
    staticClass: "u--margin-pos"
  }, [_c('p', {
    staticClass: "u--color-primary md-body-1"
  }, [_vm._v(_vm._s(_vm.displayName))]), _c('p', {
    staticClass: "u--color-grey md-caption"
  }, [_vm._v(_vm._s(_vm.info.name))])])], 1), _c('md-divider', {
    staticClass: "u_margin-top-small"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }